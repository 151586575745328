import { db, functions, httpsCallable, storage } from "config";
import {
  DocumentData,
  doc,
  updateDoc,
  collection,
  query,
  getDocs,
  getDoc,
  deleteField,
} from "firebase/firestore";
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
  getMetadata,
  deleteObject,
} from "firebase/storage";
import { compressImage } from "reusable";
import { FIRESTORE_KEYS } from "../keys";

type UpdateCondition<T> = (currentData: T) => T;

export const DataMigrationService = {
  async updateData<T extends DocumentData>(
    collectionName: string,
    fieldName: string,
    fieldUpdateCondition?: UpdateCondition<T> | unknown,
    documentId?: string,
    subCollectionName?: string,
    sourceDataCollection?: string
  ): Promise<void> {
    try {
      if (documentId) {
        const targetDocumentRef = subCollectionName
          ? doc(db, collectionName, documentId, subCollectionName, documentId)
          : doc(db, collectionName, documentId);
        let fieldData: T;

        if (sourceDataCollection) {
          const sourceDocumentRef = doc(db, sourceDataCollection, documentId);
          const sourceDocSnapshot = await getDoc(sourceDocumentRef);
          if (!sourceDocSnapshot.exists()) {
            throw new Error("Source document does not exist.");
          }
          fieldData = sourceDocSnapshot.data()[fieldName] as T;
        } else if (fieldUpdateCondition) {
          const targetDocSnapshot = await getDoc(targetDocumentRef);
          if (!targetDocSnapshot.exists()) {
            throw new Error("Target document does not exist.");
          }
          fieldData = (fieldUpdateCondition as UpdateCondition<T>)(
            targetDocSnapshot.data() as T
          );
        } else {
          throw new Error(
            "No update condition or source data collection provided."
          );
        }

        await updateDoc(targetDocumentRef, { [fieldName]: fieldData });
      } else {
        const colRef = collection(db, collectionName);
        const snapshot = await getDocs(query(colRef));

        const updatePromises = snapshot.docs.map(async (document) => {
          let fieldData: T;
          const documentId = document.id;

          if (sourceDataCollection) {
            const sourceDocumentRef = doc(db, sourceDataCollection, documentId);
            const sourceDocSnapshot = await getDoc(sourceDocumentRef);

            fieldData = (sourceDocSnapshot?.data() as T)[fieldName] || "";
          } else if (fieldUpdateCondition) {
            fieldData = (fieldUpdateCondition as UpdateCondition<T>)(
              document.data() as T
            );
          } else {
            throw new Error(
              "No update condition or source data collection provided."
            );
          }

          const targetDocumentRef = subCollectionName
            ? doc(db, collectionName, documentId, subCollectionName, documentId)
            : doc(db, collectionName, documentId);

          return updateDoc(targetDocumentRef, { [fieldName]: fieldData });
        });

        await Promise.all(updatePromises);
      }
    } catch (error) {
      console.error("Error updating data:", error);
      throw error;
    }
  },

  async updateStorageFiles(do_not_recompress: boolean = true) {
    const userPhotosRef = ref(storage, FIRESTORE_KEYS.CREATOR_PHOTOS_FOLDER);

    const isToday = (someDate: Date) => {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    };

    try {
      // List all folders in user_photos
      const userFolders = await listAll(userPhotosRef);

      for (const userFolder of userFolders.prefixes) {
        // List all photos in each user's folder
        const userPhotos = await listAll(userFolder);

        for (const photoRef of userPhotos.items) {
          try {
            // Get the metadata for each photo
            const metadata = await getMetadata(photoRef);

            // Check if the file was modified today
            if (do_not_recompress && isToday(new Date(metadata.updated))) {
              console.log(`Skipping file modified today: ${photoRef.fullPath}`);
              continue;
            }

            // Get the download URL for each photo
            const photoURL = await getDownloadURL(photoRef);

            // Fetch the photo as a blob
            const response = await fetch(photoURL);
            const blob = await response.blob();

            // Skip if the item is not a file
            if (!blob.type.startsWith("image/")) {
              console.log(`Skipping non-file item: ${photoRef.fullPath}`);
              continue;
            }

            const file = new File([blob], photoRef.name, { type: blob.type });

            // Compress the image
            const compressedFile = await compressImage(file);

            // Upload the compressed image back to the same path
            await uploadBytes(photoRef, compressedFile);

            console.log(`Compressed and uploaded ${photoRef.fullPath}`);
          } catch (error) {
            console.error(`Error processing file ${photoRef.fullPath}:`, error);
          }
        }
      }
    } catch (error) {
      console.error("Error updating storage files:", error);
      throw error;
    }
  },

  async deleteUnusedFolders() {
    const userPhotosRef = ref(storage, FIRESTORE_KEYS.CREATOR_PHOTOS_FOLDER);

    try {
      // Fetch all document IDs from profile_summary collection
      const profileSummarySnapshot = await getDocs(
        collection(db, FIRESTORE_KEYS.CREATOR_PROFILES_COLLECTION)
      );
      const profileSummaryIds = new Set(
        profileSummarySnapshot.docs.map((doc) => doc.id)
      );

      // List all folders in user_photos
      const userFolders = await listAll(userPhotosRef);

      for (const userFolder of userFolders.prefixes) {
        const folderId = userFolder.name;

        // Check if the folder ID exists in the profile_summary collection
        if (!profileSummaryIds.has(folderId)) {
          // List all files in the folder
          const files = await listAll(userFolder);

          // Delete each file in the folder
          for (const fileRef of files.items) {
            await deleteObject(fileRef);
            console.log(`Deleted file: ${fileRef.fullPath}`);
          }

          // Delete the folder itself
          console.log(`Deleted folder: ${userFolder.fullPath}`);
        }
      }
    } catch (error) {
      console.error("Error deleting unused folders:", error);
      throw error;
    }
  },

  // New function to update a field and move its value to a new field
  async moveFieldValue(
    collectionName: string,
    oldFieldName: string,
    newFieldName: string,
    documentId?: string,
    subCollectionName?: string
  ): Promise<void> {
    try {
      if (documentId) {
        const targetDocumentRef = subCollectionName
          ? doc(db, collectionName, documentId, subCollectionName, documentId)
          : doc(db, collectionName, documentId);

        const docSnapshot = await getDoc(targetDocumentRef);
        if (!docSnapshot.exists()) {
          throw new Error("Target document does not exist.");
        }
        const data = docSnapshot.data();

        // Get the value of the old field
        const oldFieldValue = data[oldFieldName];

        // Set the value to the new field
        if (oldFieldValue !== undefined) {
          await updateDoc(targetDocumentRef, {
            [newFieldName]: oldFieldValue,
            [oldFieldName]: deleteField(),
          });
        } else {
          console.log(
            `Field ${oldFieldName} does not exist in document ${documentId}.`
          );
        }
      } else {
        const colRef = collection(db, collectionName);
        const snapshot = await getDocs(query(colRef));

        const updatePromises = snapshot.docs.map(async (document) => {
          const documentId = document.id;
          const targetDocumentRef = subCollectionName
            ? doc(db, collectionName, documentId, subCollectionName, documentId)
            : doc(db, collectionName, documentId);

          const docSnapshot = await getDoc(targetDocumentRef);
          if (!docSnapshot.exists()) {
            throw new Error("Target document does not exist.");
          }
          const data = docSnapshot.data();

          // Get the value of the old field
          const oldFieldValue = data[oldFieldName];

          // Set the value to the new field
          if (oldFieldValue !== undefined) {
            return updateDoc(targetDocumentRef, {
              [newFieldName]: oldFieldValue,
              [oldFieldName]: deleteField(),
            });
          } else {
            console.log(
              `Field ${oldFieldName} does not exist in document ${documentId}.`
            );
            return Promise.resolve();
          }
        });

        await Promise.all(updatePromises);
      }
    } catch (error) {
      console.error("Error moving field value:", error);
      throw error;
    }
  },
  async sendEmailToAllUsers(): Promise<void> {
    return;
    try {
      const usersRef = collection(db, "users");
      const snapshot = await getDocs(query(usersRef));
      const sendEmail = httpsCallable(functions, "sendEmail");
      const emailPromises = snapshot.docs.map(async (userDoc) => {
        const userData = userDoc.data();
        const email = userData.email;

        if (email) {
          try {
            await sendEmail({
              to: email,
              subject: "Welcome to Kydoscope Family",
              text: `<html>
           <body style="font-family: Arial, sans-serif; color: #333; line-height: 1.6;">
             <p>Hey there, Kydo!</p>
             
             <p>Welcome to Kydoscope - your new creative home! Now that you’ve signed up, you’re officially a Kydo. We’re thrilled to be a part of your design journey and can’t wait to see what you create.</p>
             
             <p>Ready to shine even brighter? Upgrade to our <strong>Premium Plan</strong> for just ₹199/month to display your contact details and boost your profile visibility on the feed. It’s time to make your mark!</p>
             
             <ul style="list-style-type: disc; padding-left: 20px;">
               <li>30 Photos</li>
               <li>Get Project Requests from Clients</li>
               <li>Mark if you are Hiring / Available for Work</li>
               <li>Premium badge</li>
               <li>Priority visibility in explore</li>
               <li>Colour Customisation on your profile</li>
             </ul>
             
             <p><a href="https://kydoscope.com/explore-designers?search=all" style="color: #007BFF; text-decoration: none;">Explore Designers</a></p>
             
             <p>Cheers,<br>The Kydoscope Team</p>
           </body>
         </html>`,
              from: "info@kydoscope.com",
            });
            console.log(`Email sent to ${email}`);
          } catch (error) {
            console.error(`Failed to send email to ${email}:`, error);
          }
        } else {
          console.warn(`No email found for user with ID ${userDoc.id}`);
        }
      });

      await Promise.all(emailPromises);
    } catch (error) {
      console.error("Error sending emails to all users:", error);
      throw error;
    }
  },
};

// Add below useEffect in app.tsx and just refresh page to update companyName,
// update params to update any data from DB
// useEffect(() => {
//   if (loggedInUser && dataMigrationMode) {
//     const updateSearchTerms = (doc: unknown) => {
//       if (doc) {
//         return generateSearchTerms(searchFields, doc as ProfileSummary);
//       }
//     };
//     updateData(
//       "creator-profiles-summary",
//       "companyName",
//       undefined,
//       undefined,
//       undefined,
//       "creator-profiles"
//     );
//   }
// }, [loggedInUser]);

//to update search terms
// useEffect(() => {
//   if (loggedInUser && dataMigrationMode) {
//     const updateSearchTerms = (doc: unknown) => {
//       if (doc) {
//         return generateSearchTerms(searchFields, doc as any);
//       }
//     };
//     updateData("creator-profiles-summary", "searchTerms", updateSearchTerms);
//   }
// }, [loggedInUser]);
