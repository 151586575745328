import { DocumentData } from "firebase/firestore";
import { DataMigrationService } from "./../../services/data-migration/data-migration-service";

export const useDataMigration = () => {
  const updateData = (
    collectionName: string,
    fieldName: string,
    fieldUpdateCondition?: (data: DocumentData) => void,
    documentId?: string,
    subCollectionName?: string,
    sourceDataCollection?: string
  ) =>
    DataMigrationService.updateData(
      collectionName,
      fieldName,
      fieldUpdateCondition,
      documentId,
      subCollectionName,
      sourceDataCollection
    );

  const compressCreatorImages = () => DataMigrationService.updateStorageFiles();

  const deleteUnusedPhotos = () => DataMigrationService.deleteUnusedFolders();

  const sendEmailToAllUsers = () => DataMigrationService.sendEmailToAllUsers();

  const moveFieldValue = (
    collectionName: string,
    fieldName: string,
    updatedFieldName: string
  ) =>
    DataMigrationService.moveFieldValue(
      collectionName,
      fieldName,
      updatedFieldName
    );

  return {
    updateData,
    compressCreatorImages,
    deleteUnusedPhotos,
    moveFieldValue,
    sendEmailToAllUsers,
  };
};
