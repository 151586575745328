import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileSummary as ProfileSummaryType } from "models";
import { Box } from "../box";
import "./ProfileSummary.css";
import {
  Avatar,
  Colors,
  PremiumBadge,
  RoleDisplayer,
  SummaryTileStar,
  Text,
  TextVariants,
  dummyRatings,
  dummySummary,
} from "reusable";
import { useLocalStorage } from "backend";

const ProfileSummary = (props: {
  summary: ProfileSummaryType;
  profileMode?: boolean;
  canEdit?: boolean;
  isPreview?: boolean;
  feedbackMode?: boolean;
}): JSX.Element => {
  const {
    name,
    occupation,
    profilePicture,
    userId: creatorUserId,
    ratings,
    isSubscribed,
  } = props.summary || dummySummary;

  const { overall, service, design, delivery } = ratings || dummyRatings;

  const { profileMode, feedbackMode } = props;
  const goTo = useNavigate();
  const { isMobileScreen } = useLocalStorage();

  const userRatings = [
    { label: "Overall", value: overall },
    { label: "Service", value: service },
    { label: "Design", value: design },
    { label: "Delivery", value: delivery },
  ];

  return (
    <Box
      className={`profile-summary ${
        profileMode
          ? "profile-mode"
          : feedbackMode
          ? "feedback-mode"
          : "explore-mode"
      }`}
    >
      <Box className="summary-role">
        <Avatar
          url={profilePicture}
          componentKey={String(profilePicture)}
          expand={profileMode && isMobileScreen}
        />
        <RoleDisplayer
          theme="red-displayer"
          name={occupation?.split(", ")[0] || occupation}
        />
      </Box>
      <Box className="summary-details-container">
        <Box
          className="name-details"
          borderBottom={!feedbackMode ? "1px #e5e7eb solid" : ""}
        >
          <Box position="relative">
            <Text
              color={Colors.BLACK_MAIN}
              variant={TextVariants.DOUBLE_LARGE_TERNARY_FONT_BOLD}
              ellipsis
              marginBottom="8px"
            >
              {name}
              {isSubscribed && (
                <span className="premium-badge premium-badge-summary">
                  <PremiumBadge />
                </span>
              )}
            </Text>
          </Box>
          <Text
            display="block"
            color={Colors.GRAY_4}
            variant={TextVariants.MEDIUM_PRIMARY_FONT}
            ellipsis
          >
            {props.summary?.location?.place}
          </Text>
        </Box>
        {!feedbackMode && (
          <Box className="rating-details">
            <Box className="rating-details-header">
              <Box marginRight="8px" display="inline-block">
                <SummaryTileStar />
              </Box>
              <Text
                variant={TextVariants.LARGE_TERNARY_FONT_BOLD_600}
                color={Colors.BLACK_DARK}
              >
                Ratings
              </Text>
            </Box>
            <Box className="profile-stats">
              {userRatings.map((rating) => (
                <Box
                  key={rating.label}
                  className="stat-box"
                  display="inline-block"
                >
                  <Box className="state-box-value">
                    {rating.value === 0
                      ? "-"
                      : Math.round(rating.value * 10) / 10}
                    {rating.value !== 0 && (
                      <Text
                        color={Colors.GRAY_5}
                        variant={TextVariants.MEDIUM_PRIMARY_FONT_BOLD}
                        marginLeft="1.5px"
                      >
                        /5
                      </Text>
                    )}
                  </Box>
                  <Box className="state-box-label">{rating.label}</Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(ProfileSummary);
