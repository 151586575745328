import { Colors, TextVariants, Input, Text, Box } from "reusable";
import { RegularInputProps } from "./RegularInputProps";
import "./RegularInput.css";

const RegularInput = (props: RegularInputProps): JSX.Element => {
  const {
    className,
    placeholder,
    preIcon,
    postIcon,
    onChange,
    value,
    ...rest
  } = props;

  return (
    <Input
      className={`regular-input ${className}`}
      paddingLeft={preIcon ? "" : "16px"}
      placeholder={
        <Text
          variant={TextVariants.MEDIUM_15_TERNARY_FONT_BOLD}
          color={Colors.GRAY_2}
        >
          {placeholder}
        </Text>
      }
      preIcon={
        <Box padding="0 12px 0 16px" display={preIcon ? "" : "none"}>
          {preIcon}
        </Box>
      }
      placeholderLeft={preIcon ? "44px" : "16px"}
      postIcon={
        <Box cursor="pointer" padding="0 16px" display={postIcon ? "" : "none"}>
          {postIcon}
        </Box>
      }
      onChange={onChange}
      value={value}
      {...rest}
    ></Input>
  );
};

export default RegularInput;
