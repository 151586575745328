import { RecoilAtoms } from "../config/recoil-atoms";
import { CompleteProfile, CreatorProfile, ProfileSummary } from "models";
import { atom } from "recoil";

export const creatorProfileAtom = atom<CreatorProfile | null>({
  key: RecoilAtoms.CREATOR_PROFILE,
  default: null,
});

export const profilesummaryAtom = atom<Array<ProfileSummary> | null>({
  key: RecoilAtoms.PROFILE_SUMMARIES,
  default: null,
});

export const creatorAnswersAtom = atom<CompleteProfile>({
  key: RecoilAtoms.CREATOR_ANSWERS,
  default: {} as CompleteProfile,
});

export const isProfileCreationInProgressAtom = atom<boolean>({
  key: RecoilAtoms.PROFILE_CREATION_IN_PROGRESS,
  default: false,
});

export const completeProfileAtom = atom<CompleteProfile | null>({
  key: RecoilAtoms.COMPLETE_PROFILE,
  default: {} as CompleteProfile,
});

export const singleUserSummaryAtom = atom<ProfileSummary | null>({
  key: RecoilAtoms.SINGLE_USER_SUMMARY,
  default: null,
});

export const isSingleUserSummaryLoadingAtom = atom<boolean>({
  key: RecoilAtoms.IS_SINGLE_USER_SUMMARY_LOADING,
  default: false,
});

export const previousQueryAtom = atom<string>({
  key: RecoilAtoms.PREVIOUS_QUERY,
  default: "default-previous-query-fnffffnddmnfknuldlldundefuned",
});

export const isCompleteProfileLoadingAtom = atom<boolean>({
  key: RecoilAtoms.IS_COMPLETE_PROFILE_LOADING,
  default: false,
});

export const showLoadMoreButtonAtom = atom<boolean>({
  key: RecoilAtoms.SHOW_LOAD_MORE_BUTTON,
  default: false,
});

export const isProfileLoadingAtom = atom<boolean>({
  key: RecoilAtoms.IS_PROFILE_LOADING,
  default: false,
});

export const offsetAtom = atom<number>({
  key: RecoilAtoms.OFFSET,
  default: 1,
});
