export enum AppRoutes {
  LANDING = "/",
  SIGN_UP = "/sign-up",
  PROFILE = "/profile/:userId",
  PROFILE_PREVIEW = "/profile/:userId/preview",
  FILL_PROFILE_DETAILS = "/profile/:userId/fill-details",
  LOGIN = "/login",
  EXPLORE_DESIGNERS = "/explore-designers",
  FEEDBACK = "/profile/:userId/feedback",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  PRIVACY_POLICY = "/privacy-policy",
  GET_RATING = "/profile/:userId/get-verified-rating",
  ABOUT_US = "/about-us",
}
