import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Colors,
  Text,
  TextVariants,
  GoogleLogo,
  MobileGoogleLogo,
  Button,
  AppRoutes,
  Link,
  ContinueWithEmail,
  MobileContinueWithEmail,
  dummyUserId,
  WelcomToKydoscopeLarge,
} from "reusable";
import { useUser } from "backend";
import Credentials from "./components/Credentials";
import "./SignUp.css";

const SignUp = (props: { isLoginMode?: boolean }): JSX.Element => {
  //imports
  const { signUpUser, isLoading, loggedInUser, loginUser, signUpWithGoogle } =
    useUser();
  const goTo = useNavigate();
  const location = useLocation();
  const { from, afterLogin } = location.state || {
    from: { pathname: AppRoutes.EXPLORE_DESIGNERS },
  };
  const { isLoginMode = false } = props;

  //states
  const [step, setStep] = useState<number>(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleGetStarted = () => {
    const { email, password, name } = userData;
    isLoginMode
      ? loginUser(email, password)
      : signUpUser(email, password, name);
    window.scrollTo(0, 0);
  };

  const handleChange = (field: string, value: string) => {
    setUserData((prev) => ({ ...prev, [field]: value }));
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(1);
    } else {
      goTo(-1);
    }
  };

  useEffect(() => {
    const { email, password, name } = userData;
    if (email && password && (name || isLoginMode)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [userData, isLoginMode]);

  useEffect(() => {
    if (loggedInUser?.email) {
      const privateRoutes = [
        "fill-details",
        "feedback",
        "pricing",
        "get-verified-rating",
      ];
      const previousRoute = from?.pathname?.includes(dummyUserId)
        ? ""
        : from.pathname;
      if (afterLogin) {
        (window as any)?.afterLogin();
      }
      goTo(
        privateRoutes.some((route) => previousRoute?.includes(route))
          ? previousRoute
          : loggedInUser.isCreatorQuestionsAnswered
          ? AppRoutes?.PROFILE.replace(":userId", loggedInUser?.userId)
          : AppRoutes?.FILL_PROFILE_DETAILS.replace(
              ":userId",
              loggedInUser?.userId
            )
      );
    }
  }, [loggedInUser]);

  return (
    <Box className="signup-container" isLoading={isLoading}>
      <Box>
        <Box marginBottom={step === 1 ? "48px" : "30px"}>
          <WelcomToKydoscopeLarge />
        </Box>
        {step === 1 ? (
          <Box>
            <Box cursor="pointer" className="hide-for-small-only">
              <GoogleLogo onClick={signUpWithGoogle} />
            </Box>
            <Box cursor="pointer" className="show-for-small-only">
              <MobileGoogleLogo onClick={signUpWithGoogle} />
            </Box>
            <Box
              cursor="pointer"
              onClick={() => setStep(2)}
              className="hide-for-small-only"
            >
              <ContinueWithEmail />
            </Box>
            <Box
              cursor="pointer"
              onClick={() => setStep(2)}
              className="show-for-small-only"
            >
              <MobileContinueWithEmail />
            </Box>

            {!isLoginMode && (
              <Box textAlign="center" margin="34px 0">
                <Text
                  variant={TextVariants.MEDIUM_PRIMARY_FONT}
                  color={Colors.GRAY_5}
                  maxWidth="304px"
                >
                  By Signing up, you agree to our 
                  <Link
                    className="medium-primary-font"
                    underline
                    to={AppRoutes.TERMS_AND_CONDITIONS}
                  >
                    Terms of Use
                  </Link>
                   and 
                  <Link
                    className="medium-primary-font"
                    underline
                    to={AppRoutes.PRIVACY_POLICY}
                  >
                    Privacy Policy
                  </Link>
                </Text>
              </Box>
            )}
            <Text
              display="block"
              variant={TextVariants.MEDIUM_PRIMARY_FONT}
              color={Colors.GRAY_3}
              marginTop="34px"
            >
              {isLoginMode
                ? "Don't have an account?"
                : " Already have an account?"}

              <Link
                onClick={() =>
                  goTo(isLoginMode ? AppRoutes.SIGN_UP : AppRoutes.LOGIN)
                }
              >
                <Text
                  marginLeft="4px"
                  color={Colors.BLUE}
                  variant={TextVariants.MEDIUM_PRIMARY_FONT}
                >
                  {isLoginMode ? "Sign In" : "Log In"}
                </Text>
              </Link>
            </Text>
          </Box>
        ) : (
          <>
            <Text
              variant={TextVariants.MEDIUM_PRIMARY_FONT}
              color={Colors.GRAY_3}
            >
              {isLoginMode
                ? "Don't have an account?"
                : " Already have an account?"}

              <Link
                onClick={() =>
                  goTo(isLoginMode ? AppRoutes.SIGN_UP : AppRoutes.LOGIN)
                }
              >
                <Text marginLeft="4px" color={Colors.BLUE}>
                  {isLoginMode ? "Sign In" : "Log In"}
                </Text>
              </Link>
            </Text>
            <Credentials
              loginMode={isLoginMode}
              handleChange={handleChange}
              userData={userData}
            />
            <Button
              className="get-started-button"
              disabled={isButtonDisabled}
              onClick={handleGetStarted}
            >
              <Text
                variant={TextVariants.MEDIUM_15_TERNARY_FONT_EXTRA_BOLD}
                color={Colors.WHITE}
              >
                {isLoginMode ? "Login" : "Let's get started"}
              </Text>
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SignUp;
