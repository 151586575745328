// FirebaseService.ts
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import { auth, provider } from "config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query as firestoreQuery,
  setDoc,
  updateDoc,
  where,
  Query,
  WhereFilterOp,
  limit as firestoreLimit,
  DocumentData,
  orderBy,
} from "firebase/firestore";
import { db, storage } from "config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FIRESTORE_KEYS } from "../keys";
import { getFriendlyErrorMessage } from "./error-mapping"; // Assuming errorMappings.ts is in the same folder
import { haversineDistance } from "reusable";

export class FirebaseService {
  static async createUserWithEmailAndPass(email: string, password: string) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async signInWithEmailAndPass(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error: any) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async updateUserProfile(user: User, displayName: string) {
    try {
      await updateProfile(user, { displayName });
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async signInWithGoogle() {
    try {
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async onAuthStateChanges(callback: (user: User | null) => void) {
    onAuthStateChanged(auth, callback);
  }

  static async setFirestoreDoc(
    collectionPath: string,
    docId: string,
    data: any
  ) {
    try {
      const docRef = doc(db, collectionPath, docId);
      await setDoc(docRef, data, { merge: true });
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async updateFirestoreDoc(
    collectionPath: string,
    docId: string,
    data: any
  ) {
    try {
      const docRef = doc(db, collectionPath, docId);
      await updateDoc(docRef, data);
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async getFirestoreDoc(collectionPath: string, docId: string) {
    try {
      const docRef = doc(db, collectionPath, docId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        return {
          message: "Unauthorized Request.",
          type: "error" as const,
        };
      }
      return docSnap.data();
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async uploadFilesAndRetrieveURLs(userId: string, files: FileList) {
    try {
      const uploads = Array.from(files).map((file) => {
        const fileRef = ref(storage, `user_photos/${userId}/${file.name}`);
        return uploadBytes(fileRef, file).then((snapshot) =>
          getDownloadURL(snapshot.ref)
        );
      });
      return await Promise.all(uploads);
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async searchSummaries(term: string) {
    try {
      const profilesRef = collection(
        db,
        FIRESTORE_KEYS.CREATOR_PROFILES_SUMMARY
      );
      const searchQuery = firestoreQuery(
        profilesRef,
        where("searchTerms", "array-contains", term.toLowerCase())
      );
      const querySnapshot = await getDocs(searchQuery);
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  //TODO : Improve this function, optimize, reduce fetching, caching
  static async searchByLocation<T>(
    collectionName: string,
    centerLat: number,
    centerLon: number,
    maxDistance: number, // Distance in kilometers
    start: number,
    end: number,
    conditions?: { field: string; operator: WhereFilterOp; value: any }[]
  ): Promise<T[] | { message: string; type: "error" }> {
    try {
      if (start > end || !centerLat || !centerLon) {
        return [];
      }

      const queryConstraints: any[] = [];
      conditions?.forEach((condition) => {
        queryConstraints.push(
          where(condition.field, condition.operator, condition.value)
        );
      });

      // Fetch a broader set of documents initially
      let query: Query = firestoreQuery(
        collection(db, collectionName),
        ...queryConstraints,
        firestoreLimit(10) // Fetch more documents to cover the range
      );
      const initialDocs = await getDocs(query);
      const documents = initialDocs.docs;

      if (documents.length === 0) {
        return []; // No documents at all
      }

      // Filter documents based on Haversine distance
      const filteredDocuments = documents
        .map((doc) => {
          const data = doc.data() as T;
          const { lat, lng } =
            (data as any)?.location ||
            ({ latitude: 0, longitude: 0 } as unknown as {
              latitude: number;
              longitude: number;
            });
          const distance = haversineDistance(centerLat, centerLon, lat, lng);
          return { data, distance };
        })
        .filter(({ distance }) => distance <= maxDistance)
        .map(({ data }) => data);

      if (filteredDocuments.length === 0) {
        return []; // No documents within the specified distance
      }

      // Slice the results based on start and end
      const sliceStart = start - 1; // Convert to zero-based index for slicing
      const sliceEnd = Math.min(filteredDocuments.length, end); // Ensure not exceeding the actual number of documents

      return filteredDocuments.slice(sliceStart, sliceEnd);
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  //TODO : Improve this function, optimize, reduce fetching, caching
  static async fetchDataInRange<T>(
    collectionName: string,
    start: number,
    end: number,
    term?: string
  ) {
    try {
      if (start > end) {
        throw new Error("Start index cannot be greater than end index.");
      }

      if (term?.length) {
        return this.searchSummaries(term);
      }

      // Build the Firestore query
      const query: Query = firestoreQuery(
        collection(db, collectionName),
        orderBy("creationTime", "desc"), // Order by creationTime descending
        firestoreLimit(end) // Limit the number of documents fetched
      );

      // Fetch the documents
      const initialDocs = await getDocs(query);
      const documents = initialDocs.docs;

      if (documents.length === 0) {
        return []; // No documents found
      }

      if (documents.length < start) {
        return []; // Fewer documents than the start index
      }

      // Convert documents to objects
      const data = documents.map((doc) => ({ ...doc.data(), id: doc.id } as T));

      // Slice the data based on start and end indices
      const sliceStart = start - 1; // Convert to zero-based index
      const sliceEnd = Math.min(data.length, end);

      // Return the sliced data
      return data.slice(sliceStart, sliceEnd);
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }

  static async createOrUpdateSubDoc(
    collectionPath: string,
    docId: string,
    subCollectionPath: string,
    subDocId: string,
    data: DocumentData
  ): Promise<void | { message: string; type: "error" }> {
    try {
      const subDocRef = doc(
        db,
        collectionPath,
        docId,
        subCollectionPath,
        subDocId
      );
      const docSnap = await getDoc(subDocRef);

      if (docSnap.exists()) {
        await updateDoc(subDocRef, data);
      } else {
        await setDoc(subDocRef, data);
      }
    } catch (error) {
      return {
        message: getFriendlyErrorMessage(error),
        type: "error" as const,
      };
    }
  }
}
