import { useState, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  Alert,
  CreatorProfile,
  Feedback,
  FeedbackRequest,
  LoggedInUser,
  ProfileSummary,
} from "models";
import { CreatorService } from "../../services/creator/creator-service";
import {
  creatorProfileAtom,
  profilesummaryAtom,
  creatorAnswersAtom,
  singleUserSummaryAtom,
  isSingleUserSummaryLoadingAtom,
  previousQueryAtom,
  showLoadMoreButtonAtom,
  offsetAtom,
} from "../../data/creator-atoms";
import { useAlerts } from "../alert/use-alert";
import { useNotifications } from "../notifications/use-notifications";
import { Appsettings } from "config";
import { AppRoutes, generateEmailSubject, generateEmailText } from "reusable";

export const useCreator = () => {
  const [creatorProfile, setCreatorProfile] =
    useRecoilState<CreatorProfile | null>(creatorProfileAtom);
  const { sendEmail } = useNotifications();

  // for array of summaries
  const [profileSummaries, setProfileSummaries] =
    useRecoilState<Array<ProfileSummary> | null>(profilesummaryAtom);
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(false);
  // for specific user summary
  const [singleUserSummary, setSingleUserSummary] =
    useRecoilState<ProfileSummary | null>(singleUserSummaryAtom);
  const [isSingleUserSummaryLoading, setIsSingleUserSummaryLoading] =
    useRecoilState<boolean>(isSingleUserSummaryLoadingAtom);

  const [creatorAnswers, setCreatorAnswers] =
    useRecoilState<CreatorProfile>(creatorAnswersAtom);
  const [showLoadMoreButton, setShowLoadMoreButton] = useRecoilState<boolean>(
    showLoadMoreButtonAtom
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState<boolean>(false);
  const [isFeedbackRequestLoading, setIsFeedbackRequestLoading] =
    useState<boolean>(false);
  const [previousQuery, setPreviousQuery] =
    useRecoilState<string>(previousQueryAtom);
  const [limit] = useState<number>(6);
  const [offset, setOffset] = useRecoilState<number>(offsetAtom);
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );
  const [oldFeedback, setOldFeedback] = useState<Feedback>();

  const { addAlert } = useAlerts();

  const handleError = (alert: Alert) => {
    addAlert(alert);
    setIsLoading(false);
  };

  const getProfile = (userId: string) => {
    const handleResponse = (profile: CreatorProfile) => {
      setCreatorProfile(profile);
      setIsLoading(false);
    };

    if (!isLoading && userId) {
      setIsLoading(true);
      CreatorService.getProfile(userId, handleResponse, handleError);
    }
  };

  const getSummary = (userId: string) => {
    const handleResponse = (profile: ProfileSummary) => {
      setIsSingleUserSummaryLoading(false);
      setSingleUserSummary(profile);
    };

    const handleError = (alert: Alert) => {
      addAlert(alert);
      setIsSingleUserSummaryLoading(false);
    };

    if (!isSingleUserSummaryLoading && userId) {
      setIsSingleUserSummaryLoading(true);
      CreatorService.getSummary(userId, handleResponse, handleError);
    }
  };

  const resetSummaries = () => {
    setProfileSummaries(null);
    setPreviousQuery("");
  };

  const resetCreatorProfile = () => setCreatorProfile(null);

  const resetSingleUserSummary = () => setSingleUserSummary(null);

  const resetCompleteProfile = () => {
    resetCreatorProfile();
    resetSingleUserSummary();
  };

  const searchProfileSummaries = useCallback(
    (
      query: string,
      paramOffset?: number,
      userLattitude?: number,
      userLangitude?: number,
      requestAllCallback?: () => void
    ) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      const newTimer = setTimeout(() => {
        if (
          !isSummaryLoading &&
          query?.length &&
          (query?.toLowerCase() !== previousQuery?.toLowerCase() ||
            (paramOffset ? paramOffset > 1 : false))
        ) {
          setPreviousQuery(query);
          setIsSummaryLoading(true);
          const finalOffset = paramOffset || offset;
          setOffset(paramOffset ?? 1);
          const handleResponse = (profiles: Array<ProfileSummary>) => {
            setIsSummaryLoading(false);
            if (
              query === "nearby" &&
              profiles?.length === 0 &&
              requestAllCallback
            ) {
              requestAllCallback();
            } else {
              const finalProfiles = paramOffset
                ? profileSummaries
                  ? [...profileSummaries, ...profiles]
                  : profiles
                : profiles;
              setProfileSummaries(finalProfiles);
              setShowLoadMoreButton(
                finalProfiles?.length === finalOffset * limit
              );
            }
          };

          const handleError = (error: Alert) => {
            addAlert(error);
            setIsSummaryLoading(false);
          };

          CreatorService.searchProfileSummaries(
            query,
            handleResponse,
            handleError,
            limit * finalOffset - limit + 1,
            limit * finalOffset,
            userLattitude,
            userLangitude,
            Appsettings.locationSearchRadius
          );
        }
      }, 500);

      setDebounceTimer(newTimer);
    },
    [debounceTimer, isSummaryLoading]
  );

  const giveFeedback = (
    feedback: Feedback,
    oldFeedbackOfUser: Feedback,
    callback: () => void
  ) => {
    const oldRatings = oldFeedbackOfUser?.ratings?.reduce((acc, r) => {
      return { ...acc, [r.parentId]: r.rating };
    }, {});

    setIsFeedbackLoading(true);
    const handleResponse = () => {
      setIsFeedbackLoading(false);
      callback();
    };

    const handleError = (error: Alert) => {
      addAlert(error);
      setIsFeedbackLoading(false);
    };

    CreatorService.giveFeedback(
      feedback,
      oldRatings,
      handleResponse,
      handleError
    );
  };

  const getFeedback = (userId: string, profileId: string) => {
    setIsFeedbackLoading(true);
    const handleResponse = (feedback: Feedback) => {
      setIsFeedbackLoading(false);
      setOldFeedback(feedback);
    };
    const handleError = (error: Alert, display?: boolean) => {
      if (display) {
        addAlert(error);
      }
      setIsFeedbackLoading(false);
    };

    CreatorService.getFeedback(userId, profileId, handleResponse, handleError);
  };

  const sendFeedbackRequest = async (
    requestDetails: FeedbackRequest,
    loggedInUser: LoggedInUser,
    oldRequests: Array<FeedbackRequest>,
    successCallback: () => void
  ) => {
    const { userId, name } = loggedInUser;
    const {
      employerName,
      universityName,
      professorName,
      companyName,
      emailId,
    } = requestDetails;
    setIsFeedbackRequestLoading(true);
    const handleSuccess = () => {
      if (creatorProfile) {
        setCreatorProfile({
          ...creatorProfile,
          feedbackRequests: [...oldRequests, requestDetails],
        });
      }
      addAlert({
        type: "success",
        message: "Feedback request sent to provided email address !",
      });
      successCallback();
      setIsFeedbackRequestLoading(false);
    };
    const handleError = (error: Alert, display?: boolean) => {
      if (display) {
        addAlert(error);
      }
      setIsFeedbackRequestLoading(false);
    };

    const emailSubject = generateEmailSubject(
      professorName || employerName,
      name
    );

    const link = `https://kydoscope.com${AppRoutes.FEEDBACK.replace(
      ":userId",
      userId
    )}`;

    const emailText = generateEmailText(
      professorName || employerName,
      name,
      companyName || universityName || "",
      link
    );

    if (!isFeedbackLoading) {
      const emailSuccessCallback = () => {
        CreatorService.sendFeedbackRequest(
          requestDetails,
          userId,
          oldRequests,
          handleSuccess,
          handleError
        );
      };

      const errorCallback = () => {
        addAlert({
          message: "Failed to send Email",
          type: "error",
        });
        setIsFeedbackRequestLoading(false);
      };
      sendEmail(
        {
          text: emailText,
          subject: emailSubject,
          to: emailId,
        },
        emailSuccessCallback,
        errorCallback
      );
    }
  };

  return {
    getProfile,
    creatorProfile,
    isLoading,
    searchProfileSummaries,
    profileSummaries,
    isSummaryLoading,
    setOffset,
    isFeedbackLoading,
    resetSummaries,
    resetCreatorProfile,
    giveFeedback,
    showLoadMoreButton,
    offset,
    setCreatorAnswers,
    creatorAnswers,
    getSummary,
    singleUserSummary,
    isSingleUserSummaryLoading,
    resetCompleteProfile,
    resetSingleUserSummary,
    getFeedback,
    oldFeedback,
    sendFeedbackRequest,
    isFeedbackRequestLoading,
    previousQuery,
    setCreatorProfile,
  };
};
